:root {
    --bs-blue: #087bb9;
    --bs-purple: #7c09bf;
    --bs-red: #c50300;
    --bs-orange: #ff7f00;
    --bs-yellow: #ffe800;
    --bs-green: #00c566;
    --bs-black: #111111;
    --bs-gray: #dee2e6;
    --bs-gray-dark: #495057;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;

    --bs-primary: #087bb9;
    --bs-secondary: #6c757d;
    --bs-success: #00c566;
    --bs-info: #087bb9;
    --bs-warning: #ffe800;
    --bs-danger: #c50300;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;

    --bs-primary-rgb: 8, 123, 185;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 0, 197, 102;
    --bs-info-rgb: 8, 123, 185;
    --bs-warning-rgb: 255, 232, 0;
    --bs-danger-rgb: 197, 3, 0;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 17, 17, 17;
    --bs-body-bg-rgb: 242, 244, 244;

    --bs-font-sans-serif: 'Nunito', sans-serif;
    --bs-body-font: 'Nunito', sans-serif;
    --bs-heading-font: 'Mont', sans-serif;
    --bs-body-color: #111111;
    --bs-body-bg: #f2f4f4;

    --bs-border-width: 1px;
    --bs-border-style: solid;
    --bs-border-color: #dee2e6;
    --bs-border-radius: 3px;
    --bs-border-radius-sm: 1px;

    --bs-link-color: #0d6efd;
    --bs-link-hover-color: #0a58ca;
    --bs-code-color: #d63384;
    --bs-highlight-bg: #fff3cd;
}

body {
    font-family: var(--bs-body-font);
    color: var(--bs-body-color);
    font-size: 15px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4{
    font-family: var(--bs-heading-font);
    letter-spacing: -1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
    color: var(--bs-black);
    margin: 0 0 0.75rem;
}

h1,
.h1 {
    font-size: 30px;
}

h2,
.h2 {
    font-size: 24px;
}

h3,
.h3 {
    font-size: 20px;
}

h4,
.h4 {
    font-size: 16px;
    margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
    body {
        font-size: 16px;
    }

    h1,
    h2 {
        letter-spacing: -2px;
    }

    h1,
    .h1 {
        font-size: 40px;
    }

    h2,
    .h2 {
        font-size: 32px;
    }

    h3,
    .h3 {
        font-size: 26px;
    }

    h4,
    .h4 {
        font-size: 20px;
    }

    .rounded-md {
        border-radius: 3px;
    }
}

strong,
b {
    font-weight: 800;
}

.app-page,
.app-content,
.app-loader {
    background: var(--bs-body-bg);
}


.bg-green,
.app-toast.success {
    background-color: var(--bs-green) !important;
}

.bg-yellow,
.app-toast.warning {
    background-color: var(--bs-yellow) !important;
    color: var(--bs-body-color) !important;
}

.bg-orange {
    background-color: var(--bs-orange) !important;
}

.bg-red,
.app-toast.danger {
    background-color: var(--bs-red) !important;
}

.bg-blue,
.app-toast.info {
    background-color: var(--bs-blue) !important;
}

.bg-purple {
    background-color: var(--bs-purple) !important;
}

.bg-gray {
    background-color: var(--bs-gray-300) !important;
}

.bg-dark-gray {
    background-color: var(--bs-gray-400) !important;
}

.bg-black {
    background-color: var(--bs-black) !important;
}


.bg-yellow-subtle {
    background-color: #FFEC32 !important;
}

.text-green {
    color: var(--bs-green) !important;
}

.text-yellow {
    color: var(--bs-yellow) !important;
}

.text-orange {
    color: var(--bs-orange) !important;
}

.text-red {
    color: var(--bs-red) !important;
}

.text-blue {
    color: var(--bs-blue) !important;
}

.text-purple {
    color: var(--bs-purple) !important;
}

.text-gray {
    color: var(--bs-gray-400) !important;
}

.text-dark-gray {
    color: var(--bs-gray-900) !important;
}

.text-black {
    color: var(--bs-black) !important;
}


.border-green {
    border-color: var(--bs-green) !important;
}

.border-yellow {
    border-color: var(--bs-yellow) !important;
}

.border-orange {
    border-color: var(--bs-orange) !important;
}

.border-red {
    border-color: var(--bs-red) !important;
}

.border-blue {
    border-color: var(--bs-blue) !important;
}

.border-purple {
    border-color: var(--bs-purple) !important;
}

.border-gray {
    border-color: var(--bs-gray-300) !important;
}

.border-black {
    border-color: var(--bs-black) !important;
}

.border-darker {
    border-color: rgba(0, 0, 0, 0.1) !important;
}


.app-input {
    font-family: var(--bs-body-font);
}

.app-field.required:after {
    color: var(--bs-orange);
}

.app-field>.app-input:focus {
    border-color: var(--bs-orange);
}

.app-field.error>.app-input {
    border-color: var(--bs-red);
}

.app-field.error>label,
.app-field.error:after {
    color: var(--bs-red);
}